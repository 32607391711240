import * as React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import AlphaButton from "../../alpha-button/alpha-button"
import Picture from "../../picture/picture"
import { Box, Grid, Stack } from "@mui/material"
import { Link } from "gatsby"

interface MSPageprops {
  picKey: number;
}

const useStyles = makeStyles((theme) => ({
  allBox: {
    background: "rgba(255, 255, 255, 0.2)",
    backdropFilter: "blur(20px)",
    borderRadius: "10px",
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
    padding: "100px",
    [theme.breakpoints.down(1240)]: {
      padding: "50px",
    },
    [theme.breakpoints.down(1100)]: {
      padding: "20px 40px",
    },
    color: "#fff",
    fontFamily: "Poppins"
  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    [theme.breakpoints.down(900)]: {
      display: 'none',
    }
  },
  boxInfo: {
    flex: 1,
    textAlign: "right",
    [theme.breakpoints.down(900)]: {
      textAlign: "left"
    }
  },
  img: {
    width: "320px",
    
    marginBottom: 0,
    [theme.breakpoints.down(1010)]: {
      width: "240px",
      height: "240px"
    }
  },
  title: {
    fontSize: "48px",
    fontWeight: 700,
    marginBottom: "20px",
    [theme.breakpoints.down(1010)]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down(900)]: {
      textAlign: "left"
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "26px",
    }
  },
  subTitle: {
    [theme.breakpoints.down(900)]: {
      textAlign: "left"
    },
  },
  divButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 10,
    paddingRight: 10
  },
  button: {
    backgroundColor: "#323c55",
    padding: "8px 50px",
    textTransform: "none",
  }
}))

const BestTeamPage = (props: MSPageprops) => {
  const classes = useStyles()

  return <Box className={classes.allBox}>
    <Stack direction={{xs: "column", md: "row"}} alignItems={"center"}>
      <Box className={classes.divImg}>
        <Picture
          src="home-carousel-bg/application-home"
          alt="alphaws"
          preload
          preloadAbove={600}
          key={props.picKey}
          className={classes.img}
        />
      </Box>
      <Box className={classes.boxInfo}>
        <Typography className={classes.title}>Applikáció fejlesztés</Typography>
        <Typography style={{ fontSize: "18px", marginBottom: "20px" }} className={classes.subTitle}>Tedd kényelmesebbé ügyfeleid életét, nyerj elkötelezett partnereket.</Typography>
        <Link to={'/applikacio-fejlesztes'}>
          <AlphaButton className={classes.button}>Érdekel</AlphaButton>
        </Link>
      </Box>
    </Stack>
  </Box>
}

export default BestTeamPage
