import * as React from "react"
import Layout from "../components/layout"
import ReferencesSlider from "../components/references-slider/references-slider"
import HomeCarousel from "../components/home-carousel/home-carousel"
import MetaData from "../components/metadata"
import HomeInfoBlockOne from "../components/home-info-blocks/home-info-block-one"
import SkillsSlider from "../components/skills-slider/skills-slider"
import HomeInfoBlockTwo from "../components/home-info-blocks/home-info-block-two"
import PageBottom from "../components/page-bottom/page-bottom"
import { Box } from "@mui/material"

const IndexPage = () => {
  return <Layout>
    <MetaData title={"Modern fejlesztői megoldások"} url={"/"}
              description={"Olyan rendszereket fejlesztünk, amelyek kiemelkedő felhasználói élményt nyújtanak és versenyelőnyt biztosítanak valamennyi digitális csatornán."} />
    <HomeCarousel />
    <ReferencesSlider />
    <HomeInfoBlockOne />
    <SkillsSlider />
    <HomeInfoBlockTwo />
    <Box style={{marginTop: '-190px'}}>
      <PageBottom isHome={true}/>
    </Box>
  </Layout>
}

export default IndexPage
