import * as React from "react"
import "./home-carousel.css"
import ReactAliceCarousel from "react-alice-carousel"
import { Container, makeStyles } from "@material-ui/core"
import ModernSolutionsPage from "./pages/modern-solutions-page"
import OctopusConnectionPage from "./pages/octopus-connection-page"
import HomeCarouselArrows from "./home-carousel-arrows"
import Picture from "../picture/picture"
import BestTeamPage from "./pages/best-team-page"

const HomeCarousel = () => {
  const [carousel, setCarousel] = React.useState(null)
  const classes = useStyles();
  const bg = '/images/home-carousel-bg/wave.png'
  return <div className={classes.root}>
    <Container style={{position: 'relative',
      paddingTop: '55px',}}>
      {/*@ts-ignore*/}
      {/*<ReactAliceCarousel
        disableButtonsControls
        ref={(el) => (setCarousel(el))}
      >
        <BestTeamPage picKey={1}/>
        <OctopusConnectionPage picKey={2}/>
        <ModernSolutionsPage picKey={3}/>
      </ReactAliceCarousel>

      <HomeCarouselArrows carousel={carousel} />*/}
      <BestTeamPage picKey={1}/>
    </Container>
      {/*<Picture
        src="home-carousel-bg/wave"
        className={classes.background}
        sizes={[400, 600, 800]}
        alt="alphaws"
        preload
      />*/}
    <div className={classes.fallbackBg}/>
  </div>
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    paddingBottom: 40,
    marginTop: 80
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    zIndex: -1,
    '-webkit-transform': 'translate3d(0,0,0);'
  },
  fallbackBg: {
    backgroundImage: "linear-gradient(to right, #54B97D , #4CAAC3)",
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: -2
  }
}))

export default HomeCarousel
