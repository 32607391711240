import * as React from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import TwoColumnTitle from "../two-column-info/two-column-title"
import TwoColumnText from "../two-column-info/two-column-text"
import TwoColumnInfo from "../two-column-info/two-column-info"

const useStyles = makeStyles((theme) => ({
    divAll: {
      height: "auto",
      width: "100%",
      paddingTop: 30,
      paddingBottom: 180,
      backgroundImage: `url("/images/wave_bg_6.jpg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    },
    imageTitleBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(415)]: {
        flexDirection: "column",
        justifyContent: "center"
      }
    },
    image: {
      marginRight: 20,
      marginBottom: 0,
      [theme.breakpoints.down(415)]: {
        marginRight: 0
      }
    },
    imageTitleBox2: {
      display: "flex",
      alignItems: "center",
      justifyContent: 'flex-end',
      [theme.breakpoints.down(415)]: {
        flexDirection: "column-reverse",
        justifyContent: "center"
      }
    },
    image2: {
      marginLeft: 20,
      marginBottom: 0,
      [theme.breakpoints.down(415)]: {
        marginLeft: 0
      }
    }
  }
))

const HomeInfoBlockTwo = () => {
  const classes = useStyles()
  return <Box className={classes.divAll}>
    <Container>
      <TwoColumnInfo
        leftColContent={<Box className={classes.imageTitleBox}>
          <img src={"/images/pencil.svg"} height={60} width={60} className={classes.image} alt={"app"} />
          <TwoColumnTitle firstPart={"Design"}
                          secondPart={" tervezés"} />
        </Box>}
        rightColContent={<TwoColumnText
          content={<span>
            Valódi problémákat oldunk meg és javítjuk a legfontosabb üzleti mutatókat gyönyörű felületeink segítségével.
            <br />
            <br />
            A tökéletes design megalkotásához folyamatosan fejlesztünk és tesztelünk, hogy a lehető legjobb eredményt kapjuk.
          </span>} />}
        backgroundColor={"rgba(255, 255, 255, 0.4)"}
        backdropFilter={"blur(40px)"}
      />
      <Box sx={{ height: "40px" }} />
      <TwoColumnInfo
        rightColContent={<Box className={classes.imageTitleBox2}>
          <TwoColumnTitle firstPart={"Web"}
                          secondPart={" Fejlesztés"}
                          rightAlign
          />
          <img src={"/images/service.svg"} height={80} width={80} className={classes.image2} alt={"app"} />
        </Box>}
        leftColContent={<TwoColumnText
          content={<span>Gyors és biztonságos webalkalmazásokat készítünk, amelyek kizárólag az Ön üzleti céljaira lettek személyre szabva.
        <br />
        <br />
        A fejlesztés összetett folyamat, amely sikerességéhez a megfelelő partner kiválasztása és az innovatív megoldások alkalmazása elengedhetetlen.</span>} />}
        backgroundColor={"rgba(255, 255, 255, 0.4)"}
        backdropFilter={"blur(40px)"}
        reverseWrap
      />
    </Container>
  </Box>
}

export default HomeInfoBlockTwo
