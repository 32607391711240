import * as React from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import TwoColumnTitle from "../two-column-info/two-column-title"
import TwoColumnText from "../two-column-info/two-column-text"
import TwoColumnInfo from "../two-column-info/two-column-info"

const useStyles = makeStyles((theme) => ({
    divAll: {
      height: "auto",
      width: "100%",
      paddingTop: 30,
      paddingBottom: 30,
      backgroundImage: `url("/images/reference-works/bg.png")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    },
    imageTitleBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(415)]: {
        flexDirection: "column",
        justifyContent: "center"
      }
    },
    image: {
      marginLeft: 20,
      marginBottom: 0,
      [theme.breakpoints.down(415)]: {
        marginLeft: 0
      }
    },
    imageTitleBox2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.down(415)]: {
        flexDirection: "column-reverse",
        justifyContent: "center"
      }
    }
  }
))

const HomeInfoBlockOne = () => {
  const classes = useStyles()
  return <Box className={classes.divAll}>
    <Container>
      <TwoColumnInfo
        leftColContent={<TwoColumnTitle firstPart={"Kivételes fejlesztés"}
                                        secondPart={" teljeskörű támogatással és útmutatással"} />}
        rightColContent={<TwoColumnText
          content={<span>
            A digitális szolgáltatások teljes skáláját kínáljuk a kezdeti ötlettől és a stratégia megfogalmazásától a prototípus elkészítésén és a felhasználók tesztelésén át egészen a megalkotásig.
          </span>} />}
      />
      <Box sx={{ height: "40px" }} />
      <TwoColumnInfo
        rightColContent={<Box className={classes.imageTitleBox2}>
          <TwoColumnTitle firstPart={"Ötletelés"}
                          secondPart={" & Értékelés"}
                          rightAlign
          />
          <img src={"/images/bulb.svg"} height={80} width={80} className={classes.image} alt={"app"} />
        </Box>}
        leftColContent={<TwoColumnText
          content={<span>Formát adunk az ötletének. Megtervezzük és értékeljük alapvető jellemzőit, hogy elérjük az üzleti célokat és kiküszöböljük az esetleges hibákat.
            <br />
            <br />
            Nem számít, hogy egy teljesen új ötletet kell megalkotni, vagy egy már meglévőn javítani – mi itt vagyunk, hogy segítsünk.</span>} />}
        backgroundColor={"rgba(200, 200, 200, 0.2)"}
        reverseWrap
      />
    </Container>
  </Box>
}

export default HomeInfoBlockOne
