import * as React from "react"
import { useState } from "react"
import { Link, makeStyles } from "@material-ui/core"
import Picture from "../picture/picture"

const useStyles = makeStyles((theme) => ({
  div: {
    height: 120,
    cursor: "pointer",
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    height: 120,
    marginBottom: 0
  },
  smallImgClass: {
    height: 50,
    marginBottom: 0
  }
}))
const ReferencesSliderItem = ({ image, colorImage, alt, link, preload = false, smallImgClass = false }) => {
  const classes = useStyles()

  const [currentImage, setCurrentImage] = useState(image)

  return <Link className={classes.div}
               onMouseEnter={() => setCurrentImage(colorImage)}
               onMouseLeave={() => setCurrentImage(image)}
               href={link}
  >
    <Picture
      alt={alt}
      src={currentImage}
      className={smallImgClass ? classes.smallImgClass : classes.img}
      lazy={!preload}
      preload={preload}
    />
  </Link>
}

export default ReferencesSliderItem
