import * as React from "react"
import { makeStyles } from "@material-ui/core"
import ReferencesSliderItem from "./references-slider-item"
import Carousel from "react-multi-carousel"

const useStyles = makeStyles((theme) => ({
  divAll: {
    backgroundColor: "#2B3E53",
    width: "100%",
    height: 120
  },
  content: {
    maxWidth: 1200,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  carousel: {
    width: "100%",
    maxWidth: 1200,
    margin: "auto"
  }
}))

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  between: {
    breakpoint: { max: 3000, min: 1600 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1600, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 700 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 2
  }
}


const ReferencesSlider = () => {
  const classes = useStyles()

  return <div className={classes.divAll}>
    <Carousel responsive={responsive}
              className={classes.carousel}
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={5000}
              infinite={true}
    >
      <ReferencesSliderItem image="refs/eventure/eventure_logo_white_slide" colorImage="refs/eventure/eventure_logo_slide" alt="Eventure" link="/eventure" preload />
      <ReferencesSliderItem image="/refs/od/od_logo_white" colorImage="refs/od/od_logo_color" alt="Office Depot" link="/office-depot" preload />
      <ReferencesSliderItem image="refs/asztalra/logo_feher" colorImage="refs/asztalra/logo" alt="Asztalra.hu" link="/asztalra" preload/>
      <ReferencesSliderItem image="refs/arcedula/arcedula_logo_white_slide" colorImage="refs/arcedula/arcedula_logo_slide" alt="Arcedula.hu" link="/arcedula" preload/>
      <ReferencesSliderItem image="refs/festivaltravel/ft_logo_slide" colorImage="refs/festivaltravel/ft_logo_color_slide" alt="Festivaltravel" link="/festivaltravel" preload/>
      <ReferencesSliderItem image="refs/pl/ic_white" colorImage="refs/pl/ic_color" alt="PowerLife" link="/power-life" preload/>
      <ReferencesSliderItem image="refs/snowattack/snowattack_logo_white_slide" colorImage="refs/snowattack/snowattack_logo_slide" alt="Snowattack" link="/snowattack" preload/>
      <ReferencesSliderItem image="refs/nl/netlampa_logo_feher" colorImage="refs/nl/netlampa_logo" alt="Netlampa" link="/netlampa" preload/>
      <ReferencesSliderItem image="refs/playit/playit_logo_white" colorImage="refs/playit/playit_logo" alt="PlayIT" link="/play-it" preload smallImgClass/>
      <ReferencesSliderItem image="refs/budapestcomiccon/budapestcomiccon_logo_white" colorImage="refs/budapestcomiccon/budapestcomiccon_logo" alt="Budapestcomiccon" link="/budapestcomiccon" preload smallImgClass/>
      <ReferencesSliderItem image="refs/pits/ic_white" colorImage="refs/pits/ic_color" alt="PlayIT Store" link="/play-it-store" preload smallImgClass/>
      <ReferencesSliderItem image="refs/elit/ic_white" colorImage="refs/elit/ic_color" alt="Elit Safety" link="/elitsafety" preload/>
    </Carousel>
  </div>
}

export default ReferencesSlider
